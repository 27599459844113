import { render, staticRenderFns } from "./gameItem.vue?vue&type=template&id=34c2d29c&scoped=true&"
import script from "./gameItem.vue?vue&type=script&lang=js&"
export * from "./gameItem.vue?vue&type=script&lang=js&"
import style0 from "./gameItem.css?vue&type=style&index=0&id=34c2d29c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34c2d29c",
  null
  
)

export default component.exports